<template>
  <div>
    <text-element
      :layout="lyt.topleft"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
    <text-element
      :layout="lyt.topcenter"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
    <text-element
      :layout="lyt.topright"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
    <text-element
      :layout="lyt.centerleft"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
    <text-element
      :layout="lyt.centercenter"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
    <text-element
      :layout="lyt.centerright"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
    <text-element
      :layout="lyt.bottomleft"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
    <text-element
      :layout="lyt.bottomcenter"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
    <text-element
      :layout="lyt.bottomright"
      :text="txt"
      :skeleton="lyt.skeleton"
    ></text-element>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "PromotionElement",
  mixins: [layoutMixin],
  components: {
    TextElement: () => import("@/components/core/TextElement"),
  },
  props: {
    //content: Object,
  },
  data() {
    return {
      txt:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et ornare mi. Nunc blandit, nibh accumsan vestibulum vehicula, neque tortor pharetra enim, at vestibulum eros quam et nibh. Duis varius velit vitae orci viverra facilisis. In in odio elit. Aenean sed interdum magna. Pellentesque ut justo et est commodo porta sollicitudin vehicula elit. Morbi nec consectetur lorem, nec ullamcorper orci. Quisque pretium laoreet lacus, eget tincidunt mi gravida vel.",
      //txt: " TESSSSST",
      lyt: {
        skeleton: true,
        topleft: {
          color: "#000",
          unit: "%",
          font: {
            min: 10,
            max: 30,
          },
          align: 0,
          vAlign: 0,
          visible: true,
          positions: [
            {
              x: 0,
              y: 0,
              width: 35,
              height: 33,
            },
          ],
        },
        topcenter: {
          color: "#000",
          unit: "%",
          font: {
            min: 8,
            max: 50,
          },
          align: 1,
          vAlign: 0,
          visible: true,
          positions: [
            {
              x: 35,
              y: 0,
              width: 40,
              height: 10,
            },
          ],
        },
        topright: {
          color: "#000",
          unit: "%",
          font: {
            min: 8,
            max: 50,
          },
          align: 2,
          vAlign: 0,
          visible: true,
          positions: [
            {
              x: 75,
              y: 0,
              width: 25,
              height: 25,
            },
          ],
        },
        centerleft: {
          color: "#000",
          unit: "%",
          font: {
            min: 8,
            max: 50,
          },
          align: 0,
          vAlign: 1,
          visible: true,
          positions: [
            {
              x: 0,
              y: 33,
              width: 35,
              height: 33,
            },
          ],
        },
        centercenter: {
          color: "#000",
          unit: "%",
          font: {
            min: 8,
            max: 50,
          },
          align: 1,
          vAlign: 1,
          visible: true,
          positions: [
            {
              x: 35,
              y: 10,
              width: 40,
              height: 60,
            },
          ],
        },
        centerright: {
          color: "#000",
          unit: "%",
          font: {
            min: 8,
            max: 50,
          },
          align: 2,
          vAlign: 1,
          visible: true,
          positions: [
            {
              x: 75,
              y: 25,
              width: 25,
              height: 40,
            },
          ],
        },
        bottomleft: {
          color: "#000",
          unit: "%",
          font: {
            min: 8,
            max: 50,
          },
          align: 0,
          vAlign: 2,
          visible: true,
          positions: [
            {
              x: 0,
              y: 66,
              width: 35,
              height: 34,
            },
          ],
        },
        bottomcenter: {
          color: "#000",
          unit: "%",
          font: {
            min: 8,
            max: 50,
          },
          align: 1,
          vAlign: 2,
          visible: true,
          positions: [
            {
              x: 35,
              y: 70,
              width: 40,
              height: 30,
            },
          ],
        },
        bottomright: {
          color: "#000",
          unit: "%",
          font: {
            min: 8,
            max: 50,
          },
          align: 2,
          vAlign: 2,
          visible: true,
          positions: [
            {
              x: 75,
              y: 65,
              width: 25,
              height: 35,
            },
          ],
        },
      },
    };
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
  computed: {
    //
  },
  methods: {
    //
  },
};
</script>
